<template>
  <el-page-header @back="goBack">
    <template #content>
      <div class="avatar-info">
        <!-- <img src="@/assets/jingyu.png" alt="avatar" class="avatar-image" /> -->


        <el-popover :width="300"
          popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
          <template #reference>
            <el-avatar :src="userData.imageUrl" :size="60" />
          </template>
          <template #default>
            <div class="demo-rich-conent" style="display: flex; gap: 16px; flex-direction: column">

              <!-- 用于上传头像 -->
              <el-upload class="avatar-uploader" action="http://diagapi.wwftool.top/api/v1/user/myUploadFile"
                :show-file-list="false" :on-success="handleAvatarSuccess" :on-error="handleAvatarError"
                :before-upload="beforeAvatarUpload" :headers="uploadHeaders">
                <el-icon class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>

              <div>
                <el-button color="#626aef" :dark="isDark" plain size="small" @click="dialogFormVisible2 = true">用户名</el-button>
                <el-button color="#626aef" :dark="isDark" size="small" @click="dialogFormVisible = true">密码</el-button>
                <el-button color="#626aef" :dark="isDark" size="small" disabled>需求留言板</el-button>
              </div>

              <p class="demo-rich-content__desc" style="margin: 0">
                请勿泄露个人账号密码!!!
              </p>
            </div>
          </template>
        </el-popover>

        <div class="avatar-text-container">
          <span>{{ userData.job }}</span>
          <span style="color: var(--el-text-color-regular)">{{ userData.username }}</span>
          <el-tag>{{ userData.webstatus }}</el-tag>
        </div>
      </div>
    </template>
    <template #extra>
      <div class="custom-style">
        <el-segmented v-model="val1" :options="options" size="large" @change="handleSegmentChange" />
        <Drawerlog v-model="showDrawer" @update:modelValue="handleDrawerChange" />
        <ApiAnalysis v-model="showApiAnalysis" @update:modelValue="handleApiAnalysisChange" />
        <QuerySettings v-model="showQuerySettings" @update:modelValue="handleQuerySettings" />

        <el-dialog v-model="dialogFormVisible2" title="修改站点显示名称(非用户登录账号)" width="500px" :close-on-click-modal="false">
          <el-form :model="NewName">
            <el-form-item label="当前:" :label-width="formLabelWidth">
              <el-input v-model="userData.username" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item label="修改:" :label-width="formLabelWidth">
              <el-input v-model="NewName" autocomplete="off" />
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogFormVisible2 = false">取消</el-button>
              <el-button type="primary" @click="handleChangeName">提交</el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible" title="修改用户登录密码" width="500px" :close-on-click-modal="false">
          <el-form :model="NewPW">
            <el-form-item label="当前密码:" :label-width="formLabelWidth">
              <el-input v-model="NewPW.oldPW" autocomplete="off" type="password" show-password />
            </el-form-item>
            <el-form-item label="输入新密码:" :label-width="formLabelWidth">
              <el-input v-model="NewPW.NewPW1" autocomplete="off" type="password" show-password />
            </el-form-item>
            <el-form-item label="重复新密码:" :label-width="formLabelWidth">
              <el-input v-model="NewPW.NewPW2" autocomplete="off" type="password" show-password />
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取消</el-button>
              <el-button type="primary" @click="handleChangePassword">提交</el-button>
            </span>
          </template>
        </el-dialog>

      </div>
    </template>

  </el-page-header>
</template>

<script>
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Drawerlog from './Drawerlog.vue';//更新内容
import ApiAnalysis from './ApiAnalysis.vue';//在线接口类型解析
import QuerySettings from './QuerySettings.vue';//在线日志查询设置
import { Plus } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus'
import { userSaveName, userSavePassword } from '../api/users';
export default {
  components: {
    Drawerlog, // 在这里注册 Drawerlog 组件
    ApiAnalysis,
    Plus,
    QuerySettings,
  },
  setup() {
    const uploadHeaders = ref({
      Authorization: 'Bearer ' + localStorage.getItem("authToken")  // 替换为你的 token
      //'Custom-Header': 'custom_value'           // 如果需要其他自定义请求头
    })
    const dialogFormVisible = ref(false);
    const dialogFormVisible2 = ref(false);

    const NewPW = reactive({
      oldPW: '',
      NewPW1: '',
      NewPW2: ''
    })

    const NewName = ref('');
    const imageUrl = ref('')
    const showDrawer = ref(false)
    const showApiAnalysis = ref(false)
    const showQuerySettings = ref(false)
    const val1 = ref('webford')
    const options = [
      {
        label: 'webford',
        value: 'webford',
      },
      {
        label: '在线接口类型解析',
        value: '1',
      },
      {
        label: '接口文档',
        value: '2',
        disabled: true
      },
      {
        label: '更新内容',
        value: '3',
      },
      {
        label: '查询设置',
        value: '4',
      }]
    const store = useStore();

    const userData = ref(JSON.parse(JSON.stringify(store.state.userData)));

    const router = useRouter();
    const goBack = () => {
      // 假设你使用了 Vue Router，并且 router 已经被正确设置
      router.go(-1);
    };

    const handleDrawerChange = (newValue) => {
      showDrawer.value = newValue;
      if (!newValue) {
        // 如果需要，在这里更新 val1 的值  
        val1.value = 'webford'; // 更新为所需的值  
      }
    };
    const handleApiAnalysisChange = (newValue) => {
      showApiAnalysis.value = newValue;
      if (!newValue) {
        val1.value = 'webford';
      }
    };
    const handleQuerySettings = (newValue) => {
      showQuerySettings.value = newValue;
      if (!newValue) {
        val1.value = 'webford';
      }
    };
    const handleSegmentChange = (value) => {
      if (value === '4') {
        showQuerySettings.value = true;
      }
      else if (value === '3') {
        showDrawer.value = true;
      }
      else if (value === '1') {
        showApiAnalysis.value = true;
      }
      else {
        showApiAnalysis.value = false;
        showDrawer.value = false;
        showQuerySettings.value = false;
      }
    };

    // 处理上传成功的回调
    const handleAvatarSuccess = (response, uploadFile) => {
      ElMessage.success('头像上传成功!');
      userData.value.imageUrl = response.imageUrl
    }

    const handleAvatarError = (err, file, fileList) => {
      console.error('上传失败:', err);
      // 这里你可以对错误进行处理，比如展示错误提示信息
      ElMessage.error('头像上传失败，请重试！');
    }

    // 上传前检查文件类型和大小
    const beforeAvatarUpload = (rawFile) => {
      console.log('上传的文件名是:', rawFile.name)

      // 检查文件类型是否是 JPG 或 PNG
      const isValidType = rawFile.type === 'image/jpeg' || rawFile.type === 'image/png'
      if (!isValidType) {
        ElMessage.error('头像图片必须是 JPG 或 PNG 格式！')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('头像图片大小不能超过 2MB！')
        return false
      }
      return true
    }


    const handleChangeName = async () => {
      dialogFormVisible2.value = false;
      try {
        userSaveName({ username: NewName.value }).then(response => {

          ElMessage.success('设置成功！');
          store.commit('setUserData', response.data.userInfo); // 使用 Vuex Store 的 commit 方法更新状态
          userData.value = response.data.userInfo;  
        })
          .catch(error => {
            ElMessage.error('设置失败！');
          });
      } catch (error) {
        ElMessage.error('设置失败！' + error);
      }
    };

    const handleChangePassword = async () => {
      dialogFormVisible.value = false;

      // 判断是否填写了所有字段
      if (!NewPW.oldPW || !NewPW.NewPW1 || !NewPW.NewPW2) {
        ElMessage.error('请填写所有字段');
        return;
      }

      // 判断新密码和确认密码是否一致
      if (NewPW.NewPW1 !== NewPW.NewPW2) {
        ElMessage.error('新密码与确认密码不一致，请重新输入');
        return;
      }

      const form = { oldPW: NewPW.oldPW, NewPW1: NewPW.NewPW1, NewPW2: NewPW.NewPW2 };
      try {
        userSavePassword(form).then(response => {

          ElMessage.success('设置成功！');
          store.commit('setUserData', response.data.userInfo); // 使用 Vuex Store 的 commit 方法更新状态  
        })
          .catch(error => {
            ElMessage.error('设置失败！');
          });
      } catch (error) {
        ElMessage.error('设置失败！' + error);
      }

      NewPW.oldPW = '';
      NewPW.NewPW1 = '';
      NewPW.NewPW2 = '';
    };

    return {
      userData,
      goBack,
      val1, options,
      handleSegmentChange, handleDrawerChange, handleApiAnalysisChange, handleQuerySettings,
      showDrawer, showApiAnalysis, showQuerySettings,
      Plus, imageUrl, handleAvatarSuccess, beforeAvatarUpload, uploadHeaders, handleAvatarError,
      handleChangeName, NewPW, NewName, dialogFormVisible2, dialogFormVisible, handleChangePassword
    };

  }
};
</script>

<style scoped>
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #ffd100;
  --el-border-radius-base: 12px;
}

.avatar-info {
  display: flex;
  align-items: center;
  width: 100%;
  /* 垂直居中对齐 */
}

.avatar-text-container {
  display: flex;
  flex-direction: column;
  /* 设置为列方向，如果需要的话 */
  margin-left: 10px;
  /* 添加一些间距来分开头像和文字 */
}

.avatar-image {
  width: 60px;
  /* 调整为你需要的尺寸 */
  height: 60px;
  /* 调整为你需要的尺寸 */
  border-radius: 100%;
  /* 圆形头像 */
}


.avatar-uploader .avatar {
  width: 60x;
  height: 60px;
  display: block;
  border-radius: 100%;
  /* 圆形效果 */
  object-fit: cover;
  /* 图片填充方式，保持图片比例并裁剪 */
}

/* 其他样式可以根据需要进行调整 */
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  border-radius: 100%;
  /* 圆形边框 */
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 100%;
  /* 圆形效果 */
  object-fit: cover;
  /* 图片填充方式，保持图片比例并裁剪 */
}
</style>