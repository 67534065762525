<template>


    <el-dialog v-model="localVisible" width="542" @close="handleClose">
        <template #header>
            <span class="custom-dialog-title">在线诊断日志编辑页面</span>
        </template>
        <el-table :data="columns" size="small" border="false" style="width: 100%" @cell-click="handleCellClick">
            <el-table-column label="属性" prop="label" width="100"></el-table-column>
            <el-table-column label="宽度" prop="width" width="180">
                <template #default="{ row }">
                    <el-input-number v-model="row.width" :min="50" :max="600" label="宽度" size="small" />
                </template>
            </el-table-column>
            <el-table-column label="是否显示" prop="isVisible" width="100">
                <template #default="{ row }">
                    <el-switch v-model="row.isVisible" :active-value="true" :inactive-value="false" />
                </template>
            </el-table-column>
            <el-table-column label="排序" prop="order" width="130">
                <template #default="{ row }">
                    <div class="sortable">
                        <el-button :icon="Top" @click="moveUp(row)" :disabled="row.order === 1" />
                        <el-button :icon="Bottom" @click="moveDown(row)" :disabled="row.order === columns.length" />
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer">
            <el-button @click="handledefault">重置</el-button>
            <el-button @click="handleClose" >取消</el-button>
            <el-button type="primary" @click="submitForm">确定</el-button>
        </div>
    </el-dialog>

</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { userSaveLogFormat } from '../api/users';
import { ElMessage } from 'element-plus'
import { Top, Bottom } from '@element-plus/icons-vue'
export default {
    props: {
        modelValue: {
            type: Boolean
        }
    },
    setup(props, { emit }) {
        const store = useStore();
        const defaultColumns = [
            { prop: 'SnType', label: '用户类型', width: 65, isVisible: true, order: 1 },
            { prop: 'Version', label: '版本', width: 80, isVisible: true, order: 2 },
            { prop: 'Vin', label: 'Vin', width: 180, isVisible: true, order: 3 },
            { prop: 'DiagParam', label: '请求参数', width: 250, isVisible: true, order: 4 },
            { prop: 'ResData', label: '响应数据', width: 450, isVisible: true, order: 5 },
            { prop: 'FuncType', label: '功能类型', width: 60, isVisible: true, order: 6 },
            { prop: 'CreatedAt', label: '日志时间', width: 110, isVisible: true, order: 7 },
            { prop: 'DiagId', label: '软件ID', width: 120, isVisible: true, order: 8 },
            { prop: 'Sn', label: '序列号', width: 125, isVisible: true, order: 9 },
            { prop: 'runtime', label: '执行时间', width: 80, isVisible: true, order: 10 },
            { prop: 'Ip', label: 'Ip', width: 135, isVisible: true, order: 11 },
            { prop: 'Language', label: '语言', width: 60, isVisible: true, order: 12 },
            { prop: 'ProductType', label: '产品类型', width: 60, isVisible: true, order: 13 },
            { prop: 'LocalMac', label: 'LocalMac', width: 120, isVisible: true, order: 14 },
            { prop: 'WifiMac', label: 'WifiMac', width: 120, isVisible: true, order: 15 },
            { prop: 'WifiName', label: 'WifiName', width: 120, isVisible: true, order: 16 }
        ];

        const columns = ref(
            store.state.LogFormat && store.state.LogFormat.length > 0
                ? JSON.parse(JSON.stringify(store.state.LogFormat))
                : defaultColumns
        );

        // 创建一个本地响应式变量来控制抽屉的显示状态  
        const localVisible = ref(props.modelValue);

        // 监听 props.visible 的变化，并更新 localVisible  
        watch(() => props.modelValue, (newValue) => {
            localVisible.value = newValue;
        });

        // 处理弹框关闭事件  
        const handleClose = async () => {
            // 关闭弹框，并通知父组件更新 modelValue
            emit('update:modelValue', false);
        };

        const handledefault = async () => {
            columns.value = JSON.parse(JSON.stringify(defaultColumns));
        };

        // 提交表单，发送请求到服务器保存数据
        const submitForm = async () => {
            try {
                // 将 columns 数据发送给服务器
                userSaveLogFormat({ LogFormat: columns.value }).then(response => {
                    // 处理成功的响应                      
                    ElMessage.success('设置成功！');
                    store.commit('setLogFormat', JSON.parse(JSON.stringify(columns.value))); // 使用 Vuex Store 的 commit 方法更新状态  
                })
                    .catch(error => {
                        // 处理失败的响应  
                        ElMessage.error('设置失败！');
                    });

                // 关闭弹框
                handleClose();
            } catch (error) {
                // 错误处理
                ElMessage.error('设置失败！' + error);
            }
        };

        // 处理表格单元格点击事件
        const handleCellClick = (row, column, cell, event) => {
            console.log('点击了单元格', row, column);
        };


        // 向上移动排序
        const moveUp = (row) => {
            const index = columns.value.indexOf(row);
            if (index > 0) {
                const temp = columns.value[index];
                columns.value[index] = columns.value[index - 1];
                columns.value[index - 1] = temp;
                updateOrder();
            }
        };

        // 向下移动排序
        const moveDown = (row) => {
            const index = columns.value.indexOf(row);
            if (index < columns.value.length - 1) {
                const temp = columns.value[index];
                columns.value[index] = columns.value[index + 1];
                columns.value[index + 1] = temp;
                updateOrder();
            }
        };

        // 更新排序值
        const updateOrder = () => {
            columns.value.forEach((item, index) => {
                item.order = index + 1;
            });
        };

        // 返回给模板的响应式数据和方法  
        return {
            localVisible,
            handleClose, submitForm, handleCellClick, moveUp, moveDown, updateOrder,handledefault,
            columns, Top, Bottom
        };
    }
};
</script>


<style scoped>
.dialog-footer {
    text-align: right;
}

.custom-dialog-title {
  font-family: 'Poppins', 'PingFang SC', Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #333; /* 可调整颜色 */
}
</style>