<template>
    <el-container class="main-container">
        <el-main class="data-container">

            <el-tabs v-model="radio1">
                <el-tab-pane label="CN Server" name="CN Server"></el-tab-pane>
                <el-tab-pane label="US Server" name="US Server"></el-tab-pane>
            </el-tabs>

            <!-- 24 分栏-->
            <el-row :gutter="20"><!--调整行高-->


                <el-card shadow="hover" class="fixed-height-card">
                    <div class="card-content">
                        <!-- 上半部分 -->
                        <div class="top-section">
                            <h3>安全校验日志</h3>
                            <p>FordOSC.log<br>FordOSC_IVS.log</p>
                        </div>
                        <el-divider content-position="right"><el-icon>
                                <StarFilled />
                            </el-icon></el-divider>
                        <!-- 下半部分 -->
                        <div class="bottom-section">
                            <el-button type="primary" class="full-width-button" :loading="btLoading"
                                @click="handleDLSecurityLog">下载文件</el-button>
                        </div>
                    </div>
                </el-card>

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="card-content">
                        <!-- 上半部分 -->
                        <div class="top-section">
                            <h3>更新在线GGP日期</h3>
                            <p>更新后务必使用设备进行验证!!!</p>
                        </div>
                        <el-divider content-position="right">
                            <el-icon>
                                <Sunrise />
                            </el-icon>
                        </el-divider>
                        <!-- 下半部分 -->
                        <div class="bottom-section">
                            <el-button type="primary" class="full-width-button"
                                @click="handleGetGGPDate">填写日期</el-button>
                        </div>
                    </div>
                </el-card>


                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>更新MAZDA账号</h3>
                                <p>仅MAZDA开发可执行<br><br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <Sunny />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-button type="primary" class="full-width-button"
                                    @click="handleGetMacc">填写密码</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

            </el-row>

            <el-row :gutter="20"> <!--调整行高-->

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>占位1</h3>
                                <p>null<br><br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <Ship />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-button type="primary" class="full-width-button"
                                    @click="handleNothing">按钮</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>占位2</h3>
                                <p>null<br><br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <Lightning />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-button type="primary" class="full-width-button"
                                    @click="handleNothing">按钮</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>

                <el-card shadow="hover" class="fixed-height-card">
                    <div class="top-section">
                        <div class="card-content">
                            <!-- 上半部分 -->
                            <div class="top-section">
                                <h3>占位3</h3>
                                <p>null<br><br></p>
                            </div>
                            <el-divider content-position="right">
                                <el-icon>
                                    <MostlyCloudy />
                                </el-icon>
                            </el-divider>
                            <!-- 下半部分 -->
                            <div class="bottom-section">
                                <el-button type="primary" class="full-width-button"
                                    @click="handleNothing">按钮</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-row>
        </el-main>
    </el-container>

    <el-dialog v-model="dialogFormVisible2" title="修改福特在线GGP日期" width="500px">
        <el-form :model="NewGGPDate">
            <el-form-item label="当前:" :label-width="formLabelWidth">
                <el-input v-model="CurGGPDate" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item label="修改:" :label-width="formLabelWidth">
                <el-input v-model="NewGGPDate" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="handleGGPDate">提交</el-button>
            </span>
        </template>
    </el-dialog>


    <el-dialog v-model="dialogFormVisible" title="修改马自达账号密码" width="500px">
        <el-form :model="NewMacc">
            <el-form-item label="当前:" :label-width="formLabelWidth">
                <el-input v-model="CurMacc" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item label="修改:" :label-width="formLabelWidth">
                <el-input v-model="NewMacc" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="handleMacc">提交</el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script>
import { ref } from 'vue';
import { DownLoadFile,getSomeOne,setSomeOne } from '../api/users';
import { StarFilled, Sunrise, Sunny, Ship, MostlyCloudy, Lightning } from '@element-plus/icons-vue';
import { ElMessage, ElNotification } from 'element-plus'
export default {

    components: {
        StarFilled, Sunrise, Sunny, Ship, MostlyCloudy, Lightning
    },

    setup() {
        const radio1 = ref('CN Server')
        const CurGGPDate = ref('')
        const NewGGPDate = ref('')
        const CurMacc = ref('')
        const NewMacc = ref('')
        const btLoading = ref(false)
        const dialogFormVisible = ref(false);
        const dialogFormVisible2 = ref(false);
        //相应函数
        const handleDLSecurityLog = async () => {
            btLoading.value = true;
            ElMessage({ type: 'success', message: '请求下载中...', })
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                await DownLoadFile(useBaseURL, "FordOSC.log");

                setTimeout(() => {
                    DownLoadFile(useBaseURL, "FordOSC_IVS.log");
                }, 2000); //延迟2秒执行
            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
            btLoading.value = false;
        };

        const handleGetGGPDate = async () => {
            try {
                dialogFormVisible2.value = true;//显示窗口

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                const response = await getSomeOne(useBaseURL,'getGGPDate');
                CurGGPDate.value = response.data.GGPDate;
                NewGGPDate.value = response.data.GGPDate;
            } catch (error) {
                ElMessage({ type: 'warning', message: '获取HID失败，请重试' });
                dialogFormVisible2 = false;
            }
        };

        const handleGGPDate = async () => {
            try {
                if (NewGGPDate.value.length !== 10) {
                    ElMessage({ type: 'error', message: 'GGP日期填充格式不正确', })
                    return;
                }

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                dialogFormVisible2.value = false;
                await setSomeOne(useBaseURL, NewGGPDate.value,'setGGPDate');
                ElMessage({ type: 'success', message: '设置GGP日期成功' });
            } catch (error) {
                ElMessage({ type: 'warning', message: '设置GGP日期失败，请重试' });
                dialogFormVisible2 = false;
            }
        }

        const handleGetMacc = async () => {
            try {
                dialogFormVisible.value = true;//显示窗口

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                const response = await getSomeOne(useBaseURL,'getMazdaAccount');
                CurMacc.value = response.data.Acc;
                NewMacc.value = response.data.Acc;
            } catch (error) {
                ElMessage({ type: 'warning', message: '获取HID失败，请重试' });
                dialogFormVisible = false;
            }
        };

        const handleMacc = async () => {
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                dialogFormVisible.value = false;
                await setSomeOne(useBaseURL, NewMacc.value,'setMazdaAccount');
                ElMessage({ type: 'success', message: '设置mazda账号密码成功' });
            } catch (error) {
                ElMessage({ type: 'warning', message: '设置mazda账号密码失败，请重试' });
                dialogFormVisible = false;
            }
        }

        const handleNothing = async () => {
            ElNotification({
                title: '待开发',
                message: '占个位先...',
                duration: 2000,
            })
        };

        return {
            radio1, btLoading, dialogFormVisible,dialogFormVisible2,CurGGPDate,NewGGPDate,CurMacc,NewMacc,
            handleDLSecurityLog, handleNothing, handleGetGGPDate,handleGGPDate,handleGetMacc,handleMacc
        };
    },
};
</script>

<style scoped>
.el-row {
    /* 调整行与行之间的间隔 */
    margin-bottom: 30px;
}

/* 卡片容器设置 */
.fixed-height-card {
    margin: 10px;
    height: 233px;
    width: 220px;
    /* 根据实际需求调整 */
    /* display: flex; */
    /* flex-direction: column; */
}

/* 内容区域填充 */
.card-content {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex: 1; */
    padding: 0px;
    /* 控制卡片内部的内边距 */
    margin: 0px;
    /* 控制 card-content 和卡片边缘之间的外边距 */
}

/* 上半部分样式 */
.top-section {
    flex: 1;
}

/* 下半部分样式 */
.bottom-section {
    /* flex: 1; */
    /* 自动填充剩余空间 */
    /* padding-top: 15px; */
    /* overflow-y: auto; */
    /* 内容溢出时显示滚动条 */
}

.full-width-button {
    width: 100%;
    /* 使按钮的宽度占满整个容器 */
    height: 50px;
    /* 可选: 设置按钮的高度 */
}

.top-section>h3:nth-child(1) {
    margin-top: 0px;
}
</style>