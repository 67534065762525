import { createStore } from 'vuex'

export default createStore({
  state: {
    userData: {  
      job: '开发',  
      username: 'newbeeW',  
      webstatus: 'VIP'  
    },
    LogFormat: {}
  },
  getters: {
    userData: state => state.userData,
    LogFormat: state => state.LogFormat
  },
  mutations: {//同步
    setUserData(state, payload) {  
      state.userData = payload
    },
    setLogFormat(state, payload) {  // 新增的 mutation，用于更新 LogFormat 数据
      state.LogFormat = payload
    }
  },
  actions: {//异步
    updateUserData({ commit }, payload) {  
      // 在这里你可以进行异步操作，比如从API获取数据  
      commit('setUserData', payload);  
    },
    updateLogFormat({ commit }, payload) {  // 新增的 action，用于异步更新 LogFormat 数据
      commit('setLogFormat', payload)
    }
  },
  modules: {
  }
})
